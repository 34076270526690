import React from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Carousel from "react-slick";

// @material-ui/icons

// core components
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea";

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

import AccessIcon from "assets/icons/AccessIcon";
import GardenIcon from "assets/icons/GardenIcon";
import HvacIcon from "assets/icons/HvacIcon";
import LightBulbIcon from "assets/icons/LightBulbIcon";
import MediaServerIcon from "assets/icons/MediaServerIcon";
import NetworkingIcon from "assets/icons/NetworkingIcon";
import SafetyIcon from "assets/icons/SafetyIcon";
import SecurityIcon from "assets/icons/SecurityIcon";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const DomainsSection = () => {
  // const { ...text } = props.text;
  const classes = useStyles();
  const settings = {
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 7681,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1401,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 721,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 541,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title, classes.left)}>Domenii</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card carousel className={classes.cardDark}>
            <Carousel {...settings}>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/hvac">
                  <InfoArea
                    title="HVAC"
                    description=""
                    icon={HvacIcon}
                    iconColor="primary"
                    vertical
                    horizontal
                  />
                </Link>
              </div>

              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/safety">
                  <InfoArea
                    title="Siguranță"
                    description=""
                    icon={SafetyIcon}
                    iconColor="success"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/light-and-sockets">
                  <InfoArea
                    title="Iluminat"
                    description=""
                    icon={LightBulbIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/access">
                  <InfoArea
                    title="Acces"
                    description=""
                    icon={AccessIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/security">
                  <InfoArea
                    title="Securitate"
                    description=""
                    icon={SecurityIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/media-server">
                  <InfoArea
                    title="Media Server"
                    description=""
                    icon={MediaServerIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/networking">
                  <InfoArea
                    title="Networking"
                    description=""
                    icon={NetworkingIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
              <div
                className={`${classes.element} ${classes.img} ${classes.hov}`}
              >
                <Link to="/services/garden">
                  <InfoArea
                    title="Grădină"
                    description=""
                    icon={GardenIcon}
                    iconColor="info"
                    vertical
                    horizontal
                  />
                </Link>
              </div>
            </Carousel>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default DomainsSection;
