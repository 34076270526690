import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as CarbonMonoxide } from "./carbonMonoxide.svg";

export default function CarbonMonoxideIcon(props) {
  return (
    <SvgIcon {...props}>
      <CarbonMonoxide />
    </SvgIcon>
  );
}
