import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Fire } from "./fire.svg";

export default function FireIcon(props) {
  return (
    <SvgIcon {...props}>
      <Fire />
    </SvgIcon>
  );
}
