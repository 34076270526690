import { container } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const blogPageStyle = {
  container: {
    zIndex: "12",
    color: "black",
    ...container,
  },
  // title: {
  //   ...title,
  //   display: "inline-block",
  //   position: "relative",
  //   marginTop: "30px",
  //   minHeight: "32px",
  //   color: "#FFFFFF",
  //   textDecoration: "none",
  // },
  // subtitle: {
  //   fontSize: "1.313rem",
  //   maxWidth: "500px",
  //   margin: "10px auto 0",
  // },

  ...imagesStyle,
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "200px",
  },
  imgCenter: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 100px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
};

export default blogPageStyle;
