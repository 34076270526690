import coming_soon from "assets/img/blog/coming_soon.png";
import black_800x800 from "assets/img/blog/black_800x800.png";

export default {
  articleDescription: {
    items: [
      {
        title: "Articol 1",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        referinta: "https://www.google.com",
        imageSrc: coming_soon,
      },
      {
        title: "Smart HVAC",
        description:
          'Scăpați de încaperile prea calde sau prea reci, de dormitoarele cu aer "greu" dimineața sau de mirosul de mâncare gătită din "open-space-ul" dumneavoastră. <br /> Utilizând un sistem Smart HVAC veți avea temperatura perfectă în fiecare încăpere în orice oră a zilei, aer proaspăt dimineața fără disconfortul aerisirii manuale și veți scăpa de ștersul prafului de pe suprafețe și, cel mai important, veți face economii la energie.',
        referinta: "https://www.youtube.com",
        imageSrc: black_800x800,
      },
    ],
  },
};
