import React from "react";

import ReactHtmlParser from "react-html-parser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// @material-ui/icons

// core components

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

const useStyles = makeStyles(styles);

const TechnicalDetailsSection = (props) => {
  const text = props.text;
  const classes = useStyles();
  return (
    <div>
      {text.map((item) => (
        <Accordion key={"item"}>
          <AccordionSummary
            TransitionProps={{ unmountOnExit: true }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.description}>
              {ReactHtmlParser(item.description)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default TechnicalDetailsSection;
