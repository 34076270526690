import DualWanIcon from "assets/icons/networking/DualWanIcon";
import NotificationsIcon from "assets/icons/networking/NotificationsIcon";
import StatisticsDownIcon from "assets/icons/networking/StatisticsDownIcon";
import UptimeIcon from "assets/icons/networking/UptimeIcon";
import VlanIcon from "assets/icons/networking/VlanIcon";
import VpnIcon from "assets/icons/networking/VpnIcon";
import coming_soon from "assets/img/coming_soon.png";
import Networking_03 from "assets/img/networking/Networking_03.jpeg";

export default {
  bannerSection: {
    title: "",
    description: "Internet 2.0",
  },
  descriptionSection: {
    title: "Networking",
    subtitle: "Internet și rețele locale",
    description: "ToDo",
    img: coming_soon,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Uptime 99.9999%",
        description: "TODO",
        icon: UptimeIcon,
      },
      {
        title: "Solții Dual WAN",
        description: "TODO",
        icon: DualWanIcon,
      },
      {
        title: "Conectare la releaua locala prin VPN",
        description: "TODO",
        icon: VpnIcon,
      },
      {
        title: "Retele locale dedicate",
        description: "TODO",
        icon: VlanIcon,
      },
      {
        title: "Notificări",
        description: "TODO",
        icon: NotificationsIcon,
      },
      {
        title: "Statistici",
        description: "TODO",
        icon: StatisticsDownIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: coming_soon,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 1,
    },
    {
      img: coming_soon,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 2,
      rows: 2,
    },
    {
      img: Networking_03,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 1,
    },
  ],
};
