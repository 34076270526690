import React from "react";

// @material-ui/core components

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks";

import shhiIcon from "assets/shhi_logo.svg";

const dashboardRoutes = "/";

const HeaderSection = (props) => {
  return (
    <Header
      color="transparent"
      routes={dashboardRoutes}
      brand={shhiIcon}
      brandName="SHhI"
      rightLinks={<HeaderLinks {...props} />}
      fixed
      changeColorOnScroll={{
        height: 25,
        color: "white",
      }}
      {...props}
    />
  );
};

export default HeaderSection;
