import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Consultancy } from "./consultancy.svg";

export default function ConsultancyIcon(props) {
  return (
    <SvgIcon {...props}>
      <Consultancy />
    </SvgIcon>
  );
}
