import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Savings } from "./savings.svg";

export default function SavingsIcon(props) {
  return (
    <SvgIcon {...props}>
      <Savings />
    </SvgIcon>
  );
}
