import BurglaryIcon from "assets/icons/security/BurglaryIcon";
import NinjaIcon from "assets/icons/security/NinjaIcon";
import NotificationsIcon from "assets/icons/security/NotificationsIcon";
import PartitionsIcon from "assets/icons/security/PartitionsIcon";
import SurveillanceIcon from "assets/icons/security/SurveillanceIcon";
import SwitchIcon from "assets/icons/security/SwitchIcon";

import security_01 from "assets/img/security/security_01.jpg";
import coming_soon from "assets/img/coming_soon.png";

export default {
  bannerSection: {
    title: "",
    description: "Totul pentru protecția ta și a bunurilor tale.",
  },
  descriptionSection: {
    title: "Securitate",
    subtitle: "Sisteme inteligente de securitate",
    description:
      "Înlătură orice teamă şi alege să protejezi locuinţa împotriva distrugerilor sau a furtului, oferind încredere unui sistem de securitate smart compus din senzori de mişcare, senzori pentru uşi şi ferestre şi supraveghere video. Integrare posibilă cu dispecerat de alarmă şi monitorizare.",
    img: security_01,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Protecție împotriva efracției",
        description:
          "În cazul pătrunderii prin efracție sistemul declanșează alarma, notifică persoanele prestabilite, alertează dispeceratul, activează scenarii de lumini și sunet pentru derutarea persoanei.",
        icon: BurglaryIcon,
      },
      {
        title: "Zone multiple",
        description:
          "Armează automat întreaga casă sau doar anumite zone, doar pe timp de noapte sau la părăsirea locuinţei.",
        icon: PartitionsIcon,
      },
      {
        title: "Supraveghere video",
        description:
          "Înregistrează orice pătrundere sau părăsire a perimetrului și notifică persoanele prestabilite.",
        icon: SurveillanceIcon,
      },
      {
        title: "Mod noapte",
        description:
          "Monitorizează căile de acces, emite notificări în cazul în care senzorii de prezență detectează mișcare şi declanșează alarma în caz de efracție.",
        icon: NinjaIcon,
      },
      {
        title: "Buton de panica",
        description:
          "Declanșează alarma printr-o simplă apăsare de buton. Notifică persoane prestabilite privind situația în care te regăsești.",
        icon: SwitchIcon,
      },
      {
        title: "Istoric și Notificări",
        description:
          "Ești notificat de fiecare dată când cineva pătrunde sau părăsește perimetrul şi de starea ușilor sau a ferestrelor. Toate acestea vor fi salvate într-un istoric pentru access ulterior facil.",
        icon: NotificationsIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: coming_soon,
      title: "Coming Soon",
      description: "Coming Soon",
      cols: 1,
    },
    {
      img: coming_soon,
      title: "Coming Soon",
      description: "Coming Soon",
      cols: 2,
      rows: 2,
    },
    {
      img: coming_soon,
      title: "Coming Soon",
      description: "Coming Soon",
      cols: 1,
    },
  ],
};
