import defaultSectionStyle from "./defaultSection";

const gallerySectionStyle = {
  ...defaultSectionStyle,
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // overflow: "hidden",
    overflowY: "scroll",
    backgroundColor: "#fff",
  },
  gridList: {
    width: "80%",
    height: 640,
  },
};

export default gallerySectionStyle;
