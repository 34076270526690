import { title, subTitle } from "assets/jss/material-kit-react";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles";
import defaultColorsStyle from "assets/jss/material-kit-react/colors";

const defaultSectionStyle = {
  ...defaultColorsStyle,
  section: {
    padding: "32px 0 32px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "16px",
    minHeight: "32px",
    textDecoration: "none",
    color: defaultColorsStyle.darkGrey,
  },
  subTitle: {
    ...subTitle,
    minHeight: "24px",
    textDecoration: "none",
    color: defaultColorsStyle.darkGrey,
  },
  description: {
    color: defaultColorsStyle.darkGrey,
    textAlign: "left",
  },
  centerVertical: {
    margin: "auto",
  },
  ...imagesStyle,
  img: {
    padding: "20px",
  },
  heading: {
    color: defaultColorsStyle.darkGrey,
  },
  hov: {
    "&:hover,&:focus": {
      color: defaultColorsStyle.orange,
      backgroundColor: defaultColorsStyle.lightGrey,
    },
  },
  center: {
    float: "none!important",
    textAlign: "center !important",
    width: "100%",
  },
  left: {
    float: "left!important",
    display: "flex!important",
    textAlign: "left !important",
    width: "100%",
  },
  right: {
    float: "right!important",
    display: "flex!important",
    textAlign: "left !important",
    width: "100%",
  },
  parentDividerMargin: {
    margin: "0 0 24px 0",
  },
  darkColor: {
    color: defaultColorsStyle.darkGrey + "!important",
  },
};

export default defaultSectionStyle;
