import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Sprinkle } from "./sprinkle.svg";

export default function SprinkleIcon(props) {
  return (
    <SvgIcon {...props}>
      <Sprinkle />
    </SvgIcon>
  );
}
