import NightModeIcon from "assets/icons/lightAndSockets/NightModeIcon";
import NotificationsIcon from "assets/icons/lightAndSockets/NotificationsIcon";
import SavingsIcon from "assets/icons/lightAndSockets/SavingsIcon";
import SwitchIcon from "assets/icons/lightAndSockets/SwitchIcon";
import VoiceCommands from "assets/icons/lightAndSockets/VoiceCommandsIcon";

import lights_02 from "assets/img/lightAndSockets/lights_02.jpg";
import lights_03 from "assets/img/lightAndSockets/lights_03.jpg";
import lights_001 from "assets/img/lightAndSockets/lights_001.jpg";
import socket_01 from "assets/img/lightAndSockets/socket_01.jpg";

export default {
  bannerSection: {
    title: "",
    description: "Iluminatul Smart nu este doar despre becuri.",
  },
  descriptionSection: {
    title: "Iluminat și prize",
    subtitle: "",
    description:
      "Creează scene, rutine şi reguli de iluminat potrivite nevoilor tale. De asemenea, monitorizează consumul de energie și controlează alimentarea dispozitivelor non-smart şi smart folosind o soluţie inteligentă de iluminat și alimentare cu energie.",
    img: lights_001,
  },
  advantagesSection: {
    title: "Mai mult decât iluminat",
    items: [
      {
        title: "Confort",
        description:
          "Folosirea întrerupătoarelor mobile, a telecomenzilor sau a programelor adaptive de iluminat.",
        icon: SwitchIcon,
      },
      {
        title: "Economie de Energie",
        description:
          "Oprirea alimentării cu energie dacă senzorul de prezență nu detectează mișcare.",
        icon: SavingsIcon,
      },
      {
        title: "Mod noapte",
        description:
          "Posibilitatea de reglare a intensității luminoase la activarea senzorilor de prezență noaptea.",
        icon: NightModeIcon,
      },
      {
        title: "Comenzi vocale",
        description:
          "Accesează toate dispozitivile conectate printr-o simplă comandă.",
        icon: VoiceCommands,
      },
      {
        title: "Notificări",
        description: "Fii la curent cu toate acțiunile realizate.",
        icon: NotificationsIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: lights_03,
      title: "Controlul iluminatului pe plan de nivel",
      description: "Poziționarea surselor de lumină conform cu poziția reală.",
      cols: 1,
      rows: 2,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/lights_01.mp4",
      title: "Control bec smart prin gesturi",
      description:
        "Gesturile sunt interpretate de echipamentul smart și comenzile sunt transmise becului.",
      cols: 2,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/lights_02.mp4",
      title: "Dimming bec smart prin gesturi",
      description:
        "Gesturile sunt interpretate de echipamentul smart și comenzile sunt transmise becului.",
      cols: 2,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/socket_01.mp4",
      title: "Controlul alimentării cu energie",
      description:
        "Echipamente smart utilizate in controlul alimentării cu energie.",
      cols: 2,
      rows: 2,
    },
    {
      img: lights_02,
      title: "Controlul iluminatului",
      description: "Echipamente smart utilizate in controlul iluminatului.",
      cols: 1,
    },
    {
      img: socket_01,
      title: "Priză smart",
      description: "Control sursă alimentare.",
      cols: 1,
    },
  ],
};
