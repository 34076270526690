import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Uptime } from "./uptime.svg";

export default function UptimeIcon(props) {
  return (
    <SvgIcon {...props}>
      <Uptime />
    </SvgIcon>
  );
}
