export default {
  title: "Servicii",
  tabsLabels: {
    Hvac: "HVAC",
    Safety: "Siguranță",
    LightAndSockets: "Iluminat și Prize",
    Access: "Acces",
    Security: "Securitate",
    MediaServer: "Media Server",
    Networking: "Networking",
    Garden: "Grădină",
  },
};
