import { container, title } from "assets/jss/material-kit-react";
import defaultColorsStyle from "assets/jss/material-kit-react/colors";

const defaultPageStyle = {
  ...defaultColorsStyle,
  container: {
    zIndex: "12",
    color: defaultColorsStyle.white,
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "16px",
    minHeight: "32px",
    textDecoration: "none",
    color: defaultColorsStyle.darkGrey,
  },
  subtitle: {
    fontSize: "1.313rem",
    fontWeight: "500",
    maxWidth: "500px",
    marginTop: "10px",
    color: defaultColorsStyle.darkGrey,
  },
  main: {
    position: "relative",
    zIndex: "3",
    background: defaultColorsStyle.white,
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  center: {
    float: "center!important",
    display: "flex!important",
    textAlign: "center !important",
  },
  left: {
    float: "left!important",
    display: "flex!important",
    textAlign: "left !important",
    width: "100%",
  },
  right: {
    float: "right!important",
    display: "flex!important",
    textAlign: "left !important",
  },
  contentCenter: {
    justifyContent: "center",
  },
  contentScrollable: {
    flexGrow: "0",
  },
};

export default defaultPageStyle;
