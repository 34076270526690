import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as VoiceCommands } from "./voiceCommands.svg";

export default function VoiceCommandsIcon(props) {
  return (
    <SvgIcon {...props}>
      <VoiceCommands />
    </SvgIcon>
  );
}
