/*eslint-disable*/
import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {
  AppsOutlined,
  ContactSupportOutlined, DomainOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  MessageOutlined, QuestionAnswerOutlined
} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Link to="/" className={classes.navLink}>
                    <HomeOutlined className={classes.icons}/> Acasa
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/profile" className={classes.navLink}>
                    <ContactSupportOutlined className={classes.icons}/> Cine Suntem?
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/services" className={classes.navLink}>
                    <DomainOutlined className={classes.icons}/> Domenii
                </Link>
            </ListItem>
            <ListItem className={classes.listItemHide}>
                <Link to="/portfolio" className={classes.navLink}>
                    <FolderOpenOutlined className={classes.icons}/> Portofoliu
                </Link>
            </ListItem>
            <ListItem className={classes.listItemHide}>
                <Link to="/blog" className={classes.navLink}>
                    <MessageOutlined className={classes.icons}/> Blog
                </Link>
            </ListItem>
            <ListItem className={classes.listItemHide}>
                <Link to="/contact" className={classes.navLink}>
                    <QuestionAnswerOutlined className={classes.icons}/> Contact
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="facebook-tooltip"
                    title="Follow us on Facebook"
                    placement={window.innerWidth > 1280 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://www.facebook.com/Smart-HouseHold-Integration-102500115254695"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-facebook"}/>
                    </Button>
                </Tooltip>
            </ListItem>
            {/*<ListItem className={classes.listItem}>
                <Tooltip
                    id="youtube-tooltip"
                    title="Follow us on YouTube"
                    placement={window.innerWidth > 1280 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        href="https://www.youtube.com"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <i className={classes.socialIcons + " fab fa-youtube"}/>
                    </Button>
                </Tooltip>
            </ListItem>*/}
        </List>
    );
}
