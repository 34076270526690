import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as CustomerSupport } from "./customerSupport.svg";

export default function CustomerSupportIcon(props) {
  return (
    <SvgIcon {...props}>
      <CustomerSupport />
    </SvgIcon>
  );
}
