import React from "react";

import ReactHtmlParser from "react-html-parser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";
import classNames from "classnames";

const useStyles = makeStyles(styles);

const DescriptionSection = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid,
    classes.imgCenter
  );
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title)}>{text.title}</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.centerVertical}>
          <h3 className={classNames(classes.subTitle, classes.left)}>
            {text.subtitle}
          </h3>
          <p className={classNames(classes.description)}>
            {ReactHtmlParser(text.description)}
          </p>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <img src={text.img} alt="..." className={imageClasses} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default DescriptionSection;
