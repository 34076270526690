import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/blogPage.js";

// Sections for this page
import HeaderSection from "views/Components/Sections/HeaderSection.js";
import ArticleDescription from "views/Components/Sections/ArticleDescription";
import Button from "components/CustomButtons/Button";

import blogText from "./text/blogContent";

const useStyles = makeStyles(styles);

export default function BlogPage(props) {
  const articlesData = blogText.articleDescription.items;
  const classes = useStyles();
  return (
    <div>
      <HeaderSection props={props} />
      <Parallax filter image={require("assets/img/banner_02.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>Blog</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      {articlesData.map((item) => (
        <div
          key={item}
          className={classNames(classes.main, classes.mainRaised)}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <ArticleDescription text={item} />;
                <Button color="danger">Citeste mai mult</Button>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      ))}

      <Footer />
    </div>
  );
}
