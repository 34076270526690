import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as HomeCinema } from "./homeCinema.svg";

export default function HomeCinemaIcon(props) {
  return (
    <SvgIcon {...props}>
      <HomeCinema />
    </SvgIcon>
  );
}
