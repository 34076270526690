import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Burglary } from "./burglary.svg";

export default function BurglaryIcon(props) {
  return (
    <SvgIcon {...props}>
      <Burglary />
    </SvgIcon>
  );
}
