import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Partitions } from "./partitions.svg";

export default function PartitionsIcon(props) {
  return (
    <SvgIcon {...props}>
      <Partitions />
    </SvgIcon>
  );
}
