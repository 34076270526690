import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Vpn } from "./vpn.svg";

export default function VpnIcon(props) {
  return (
    <SvgIcon {...props}>
      <Vpn />
    </SvgIcon>
  );
}
