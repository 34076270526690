import AccessIcon from "assets/icons/access/AccessIcon";
import HistoryIcon from "assets/icons/access/HistoryIcon";
import NotificationsIcon from "assets/icons/access/NotificationsIcon";
import SmartDoorIcon from "assets/icons/access/SmartDoorIcon";

import access_01 from "assets/img/access/access_01.jpg";
import coming_soon from "assets/img/coming_soon.png";

export default {
  bannerSection: {
    title: "",
    description: "Telecomenzile și cheile într-o singură aplicație",
  },
  descriptionSection: {
    title: "Acces",
    subtitle: "",
    description:
      "Controlul accesului a devenit mai simplu – deschide uşile de acces sau garajul şi poarta în momentul plecării sau al revenirii acasă. Verifică starea accesului – poți opta pentru acces pe baza datelor biometrice.",
    img: access_01,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Controlul accesului",
        description:
          "Închide ușa, garajul sau poarta şi armează casa printr-un singur click.",
        icon: AccessIcon,
      },
      {
        title: "Starea ușilor și a ferestrelor",
        description: "Integrarea cu sistemul de securitate și/sau HVAC.",
        icon: SmartDoorIcon,
      },
      {
        title: "Notificări",
        description: "Primești alerte despre starea căilor de acces.",
        icon: NotificationsIcon,
      },
      {
        title: "Istoric",
        description: "Trasabilitatea accesului în orice moment.",
        icon: HistoryIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: coming_soon,
      title: "Coming soon",
      description: "Coming soon",
      cols: 2,
      rows: 2,
    },
    {
      img: coming_soon,
      title: "Coming soon",
      description: "Coming soon",
      cols: 1,
    },
    {
      img: coming_soon,
      title: "Coming soon",
      description: "Coming soon",
      cols: 1,
    },
  ],
};
