import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as MediaServer } from "./mediaServer.svg";

export default function MediaServerIcon(props) {
  return (
    <SvgIcon {...props}>
      <MediaServer />
    </SvgIcon>
  );
}
