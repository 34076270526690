import ConsultancyIcon from "assets/icons/ConsultancyIcon.js";
import DesignIcon from "assets/icons/DesignIcon.js";
import ImplementIcon from "assets/icons/ImplementIcon.js";
import CustomerSupportIcon from "assets/icons/CustomerSupportIcon.js";

export default {
  bannerSection: {
    title: "Smart Household Integration",
    description: "Partenerul tău în soluții Smart Building",
  },
  servicesSection: {
    title: "Servicii",
    topics: [
      {
        title: "Consultanță",
        description:
          "Suntem atenți la nevoile tale. Lucrăm împreună și identificăm soluțiile potrivite pentru ca trecerea la tehnologia Smart să fie la nivelul așteptărilor tale.",
        icon: ConsultancyIcon,
      },
      {
        title: "Proiectare",
        description:
          "Configurarea sistemului pentru atingerea unui grad de confort și eficiență energetică maxim.",
        icon: DesignIcon,
      },
      {
        title: "Implementare",
        description:
          "Venim în sprijinul tău pentru punerea în aplicare a soluției, astfel încat garantăm rezultatul scontat.",
        icon: ImplementIcon,
      },
      {
        title: "Suport Tehnic",
        description:
          "On-line sau telefonic, răspundem solicitărilor tale, pentru ca tu să te bucuri din plin de noul tău proiect Smart.",
        icon: CustomerSupportIcon,
      },
    ],
  },
  platformSection: {
    title: "Platforma",
    subTitle: "Home Assistant",
    description:
      "Propunem Home Assistant ca fiind platforma optimă pentru proiectul tău smart, întrucât confidențialitatea și controlul local sunt puse pe primul plan.",
    button: "Află mai multe",
  },
  productSection: {
    title: "",
    description: "",
  },
};
