const defaultColorsStyle = {
  white: "#FFF",
  darkWhite: "#F4F4F4",
  darkWhiteSemiTransparent: "rgba(244,244,244,0.8)",
  lightGrey: "#A4A4A4",
  darkGrey: "#444",
  lightBlue: "#038FC7",
  darkBlue: "#00008B",
  purple: "#7B00FF",
  orange: "#F66614",
  green: "#68C34F",
};

export default defaultColorsStyle;
