import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Switch } from "./switch.svg";

export default function SwitchIcon(props) {
  return (
    <SvgIcon {...props}>
      <Switch />
    </SvgIcon>
  );
}
