import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Smartphone } from "./smartphone.svg";

export default function SmartphoneIcon(props) {
  return (
    <SvgIcon {...props}>
      <Smartphone />
    </SvgIcon>
  );
}
