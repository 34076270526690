import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Garden } from "./garden.svg";

export default function GardenIcon(props) {
  return (
    <SvgIcon {...props}>
      <Garden />
    </SvgIcon>
  );
}
