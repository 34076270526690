import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from "assets/jss/material-kit-react.js";
import defaultColorsStyle from "../colors";

const infoStyle = {
  infoArea: {
    maxWidth: "420px",
    margin: "0 auto",
    height: "100%",
  },
  horizontal: {
    textAlign: "center",
  },
  grid: {
    display: "grid",
  },
  iconWrapper: {
    float: "center",
  },
  primary: {
    color: primaryColor,
  },
  warning: {
    color: warningColor,
  },
  danger: {
    color: dangerColor,
  },
  success: {
    color: successColor,
  },
  info: {
    color: infoColor,
  },
  rose: {
    color: roseColor,
  },
  gray: {
    color: grayColor,
  },
  icon: {
    width: "36px",
    height: "36px",
  },
  descriptionWrapper: {
    overflow: "hidden",
    color: defaultColorsStyle.darkGrey,
  },
  title: {
    ...title,
    color: defaultColorsStyle.darkGrey,
    margin: "8px 0px 8px 0px",
  },
  description: {
    overflow: "hidden",
    color: defaultColorsStyle.darkGrey,
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
};

export default infoStyle;
