import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Venting } from "./venting.svg";

export default function VentingIcon(props) {
  return (
    <SvgIcon {...props}>
      <Venting />
    </SvgIcon>
  );
}
