import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as NightMode } from "./nightMode.svg";

export default function NightModeIcon(props) {
  return (
    <SvgIcon {...props}>
      <NightMode />
    </SvgIcon>
  );
}
