import BackupIcon from "assets/icons/mediaServer/BackupIcon";
import HomeCinemaIcon from "assets/icons/mediaServer/HomeCinemaIcon";
import NotificationsIcon from "assets/icons/mediaServer/NotificationsIcon";
import SpeakersIcon from "assets/icons/mediaServer/SpeakersIcon";
import StatisticsDownIcon from "assets/icons/mediaServer/StatisticsDownIcon";
import VoiceCommandsIcon from "assets/icons/mediaServer/VoiceCommandsIcon";
import coming_soon from "assets/img/coming_soon.png";

export default {
  bannerSection: {
    title: "",
    description: "Administrează-ți conținutul simplu și rapid",
  },
  descriptionSection: {
    title: "Media Server",
    subtitle:
      "Controlul deplin al conţinutului dumneavoastră prin stocarea în HomeCloud.",
    description:
      "Albumele de fotografii şi cardurile de memorie au devenit de domeniul trecutului. Folosind o soluţie Media smart, fotografiile, videoclipurile, filmele şi muzica devin mai accesibile ca niciodată.",
    img: coming_soon,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Home Cinema",
        description: "TODO",
        icon: HomeCinemaIcon,
      },
      {
        title: "Sistem audio multizone",
        description: "TODO",
        icon: SpeakersIcon,
      },
      {
        title: "Comenzi vocale",
        description: "TODO",
        icon: VoiceCommandsIcon,
      },
      {
        title: "Backup media",
        description: "TODO",
        icon: BackupIcon,
      },
      {
        title: "Notificări",
        description: "TODO",
        icon: NotificationsIcon,
      },
      {
        title: "Statistici",
        description: "TODO",
        icon: StatisticsDownIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: coming_soon,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 1,
    },
    {
      img: coming_soon,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 2,
      rows: 2,
    },
    {
      img: coming_soon,
      title: "Coming Soon",
      author: "Coming Soon",
      cols: 1,
    },
  ],
};
