import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(styles);

const CompanySection = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid,
    classes.imgCenter
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="left">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title, classes.left)}>
            {text.title}
          </h2>
        </GridItem>
        <GridItem sm={12} md={7} className={classes.centerVertical}>
          <p className={classes.description}>
            {ReactHtmlParser(text.description)}
          </p>
        </GridItem>
        <GridItem sm={12} md={5}>
          <img src={text.src} alt="..." className={imageClasses} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CompanySection;
