import { container, primaryColor } from "assets/jss/material-kit-react.js";
//import React from "react";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  left: {
    float: "left!important",
    width: "100%",
    display: "flex",
    textAlign: "center",
  },
  right: {
    padding: "0",
    margin: "0",
    float: "right!important",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container,
  list: {
    textAlign: "center",
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    width: "inherit",
  },

  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  inlineVertical: {
    display: "column",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  arrange: {
    flexWrap: "nowrap",
    justify: "center",
    alignItems: "center",
  },
  paragraph: {
    textAlign: "center",
  },
  gridInline: {
    display: "flex",
    flexWrap: "nowrap",
  },
  styleH: {
    marginTop: 0,
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "underline",
  },
  smallVertical: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  noPadding: {
    padding: 0,
  },
  logo: {
    maxWidth: 70,
    marginLeft: "16px",
    marginRight: "10px",
  },
};
export default footerStyle;
