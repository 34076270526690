import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Security } from "./security.svg";

export default function SecurityIcon(props) {
  return (
    <SvgIcon {...props}>
      <Security />
    </SvgIcon>
  );
}
