import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Speakers } from "./speakers.svg";

export default function SpeakersIcon(props) {
  return (
    <SvgIcon {...props}>
      <Speakers />
    </SvgIcon>
  );
}
