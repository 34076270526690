import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea";

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

const useStyles = makeStyles(styles);

const HighlightsSection = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  return (
    <div>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title)}>{text.title}</h2>
        </GridItem>
        {text.items.map((item) => (
          <GridItem
            key={item}
            xs={12}
            sm={6}
            md={4}
            className={classes.parentDividerMargin}
          >
            <InfoArea
              title={item.title}
              description={item.description}
              icon={item.icon}
              vertical
              horizontal
              grid
              divider
            />
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default HighlightsSection;
