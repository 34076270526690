import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import GallerySection from "views/sections/GallerySection";
import HeaderSection from "views/Components/Sections/HeaderSection.js";

import styles from "assets/jss/material-kit-react/views/defaultPage.js";

// Sections for this page
// import PoweredByCarouselSection from "./Sections/PoweredByCarouselSection";
import CompanySection from "./Sections/CompanySection";

import banner_landscape from "assets/img/profile/banner_landscape.jpg";
import banner_portrait from "assets/img/profile/banner_portrait.jpg";

// Text for this page
import profileText from "./text/profileContent";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <HeaderSection props={props} />
      <Parallax filter image={matches ? banner_landscape : banner_portrait}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>
                {profileText.bannerSection.title}
              </h1>
              <h3 className={classes.subtitle}>
                {profileText.bannerSection.description}
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <CompanySection text={profileText.companySection} />
          <GallerySection
            tiles={profileText.gallerySection}
            settings={profileText.gallerySectionSettings}
          />
          {/*<div className={classes.description}>*/}
          {/*  {profileText.brandsSection.title}*/}
          {/*</div>*/}
          {/*<PoweredByCarouselSection />*/}
          {/*<TechnicalDetailsSection text={profileText.technicalSection} />*/}
        </div>
      </div>
      <Footer />
    </div>
  );
}
