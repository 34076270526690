import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Design } from "./design.svg";

export default function DesignIcon(props) {
  return (
    <SvgIcon {...props}>
      <Design />
    </SvgIcon>
  );
}
