import React from "react";
import { Link } from "react-router-dom";

// nodejs library to set properties for components
import PropTypes from "prop-types";

// nodejs library that concatenates classes
import classNames from "classnames";

// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Hidden from "@material-ui/core/Hidden";

import shhiIcon from "assets/shhi_logo.svg";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classNames(classes.container)}>
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <Hidden smDown>
              <div className={classes.left}>
                <Link to={"routes" in props ? props.routes : "/"}>
                  <img className={classes.logo} src={shhiIcon} alt="SHhI" />
                </Link>
              </div>
            </Hidden>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <Hidden smDown>
              <div className={classes.center}>
                <List className={classes.list}>
                  <GridContainer className={classes.gridInline}>
                    <GridItem xs={12} sm={3} md={4}>
                      <h3 className={classes.styleH}>Informatii companie</h3>
                      <p>Adresa: mun. Turda, str.Lotus, nr. 46, jud. Cluj</p>
                      <p>CUI: 32117468</p>
                      <p>Reg com: J12/2539/08.08.2013</p>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={5}>
                      <h3 className={classes.styleH}>Contact</h3>
                      <p>
                        Adresa: mun. Cluj-Napoca, str. Florilor 23, jud. Cluj
                      </p>
                      <p>Telefon: - - </p>
                      <p>Email: contact@shhi.ro</p>
                    </GridItem>
                    <GridItem xs={12} sm={5} md={3}>
                      <h3 className={classes.styleH}>
                        Informatii suplimentare
                      </h3>
                      <a
                        href="https://ec.europa.eu/consumers/odr/main/index.cfm"
                        rel="noreferrer"
                        target="_blank"
                        className={classNames(classes.block, classes.noPadding)}
                      >
                        Solutionarea litigiilor
                      </a>
                      <a
                        href="https://anpc.ro/"
                        rel="noreferrer"
                        target="_blank"
                        className={classNames(classes.block, classes.noPadding)}
                      >
                        ANPC
                      </a>
                    </GridItem>
                  </GridContainer>
                </List>
              </div>
            </Hidden>
            <Hidden smUp>
              <div className={classes.right}>
                <List className={classes.list}>
                  <ListItem className={classes.smallVertical}>
                    <h3 className={classes.styleH}>Informatii companie</h3>
                    <p>Adresa: mun. Turda, str.Lotus, nr. 46, jud. Cluj</p>
                    <p>CUI: 32117468</p>
                    <p>Reg com: J12/2539/08.08.2013</p>
                  </ListItem>
                  <ListItem className={classes.smallVertical}>
                    <h3 className={classes.styleH}>Contact</h3>
                    <p>Adresa: mun. Cluj-Napoca, str. Florilor 23, jud. Cluj</p>
                    <p>Telefon: -</p>
                    <p>Email: contact@shhi.ro</p>
                  </ListItem>
                  <ListItem className={classes.smallVertical}>
                    <h3 className={classes.styleH}>Informatii suplimentare</h3>
                    <a
                      href="https://ec.europa.eu/consumers/odr/main/index.cfm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Solutionarea litigiilor
                    </a>
                    <a
                      href="https://anpc.ro/"
                      rel="noreferrer"
                      target="_blank"
                      className={classNames(classes.block, classes.noPadding)}
                    >
                      ANPC
                    </a>
                  </ListItem>
                </List>
              </div>
            </Hidden>
          </GridItem>
        </GridContainer>
        <div className={classes.center}>
          {" "}
          &copy;{1900 + new Date().getYear()} SRG Solutions, All rights reserved{" "}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
