import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Switch, useLocation } from "react-router-dom";

import { StyledEngineProvider } from "@material-ui/core";
import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import PortfolioPage from "./views/PortofoliuPage/PortfolioPage";
import BlogPage from "./views/BlogPage/BlogPage.js";
import ServicesPage from "./views/ServicesPage/ServicesPage";

export const history = createBrowserHistory();

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const renderApp = () => {
  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <HashRouter basename="/">
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/profile" component={ProfilePage} />
          <Route path="/services" component={ServicesPage} />
          <Route path="/portfolio" component={PortfolioPage} />
          <Route path="/blog" component={BlogPage} />
        </Switch>
      </HashRouter>
    </StyledEngineProvider>,
    document.getElementById("root")
  );
};

renderApp();
