import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as WaterLeak } from "./waterLeak.svg";

export default function WaterLeakIcon(props) {
  return (
    <SvgIcon {...props}>
      <WaterLeak />
    </SvgIcon>
  );
}
