import LightsIcon from "assets/icons/garden/LightsIcon";
import MoistureIcon from "assets/icons/garden/MoistureIcon";
import PoolIcon from "assets/icons/garden/PoolIcon";
import SprinkleIcon from "assets/icons/garden/SprinkleIcon";
import SurveillanceIcon from "assets/icons/garden/SurveillanceIcon";
import coming_soon from "assets/img/coming_soon.png";

export default {
  bannerSection: {
    title: "",
    description:
      "Cele mai frumoase grădini sunt acelea care au o mulțime de plante. Creează-ți oaza de relaxare și îngrijește-o cu eforturi minime.",
  },
  descriptionSection: {
    title: "Grădina",
    subtitle: "Peluza perfectă la un click distanță",
    description:
      "Oferă colțului tău de natura atenția cuvenită. Un sistem inteligent de îngrijire a gazonului și plantelor va iriga, folosind integrarea cu prognoza meteo, va programa tunderea gazonului în funcție de gradul de creștere şi va notifica, conform unui program, activitățile de îngrijire, toaletare, supraînsămânțare şi aplicare îngrășământ sau fertilizanți.",
    img: coming_soon,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Controlul irigării",
        description: "Definește intervalele de irigare din aplicație.",
        icon: SprinkleIcon,
      },
      {
        title: "Optimizarea consumului de apa",
        description:
          "Adaptează consumul de apă în funcție de prognoza meteo și datele senzorilor de umiditate de la nivelul solului.",
        icon: MoistureIcon,
      },
      {
        title: "Controlul ambientului",
        description:
          "La activarea senzorului de prezență se va opri irigarea, şi dacă este cazul, se vor aprinde luminile exterioare.",
        icon: LightsIcon,
      },
      {
        title: "Suparvegherea celor mici",
        description:
          "Urmărește-l pe cel mic – primește notificări daca părăsește perimetrul prestabilit.",
        icon: SurveillanceIcon,
      },
      {
        title: "Piscina",
        description:
          "Incălzirea automată în funcție de starea vremii, acoperirea ei în condiții nefavorabile.",
        icon: PoolIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: coming_soon,
      title: "Image",
      author: "author",
      cols: 2,
      rows: 2,
    },
    {
      img: coming_soon,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: coming_soon,
      title: "Image",
      author: "author",
      cols: 1,
    },
  ],
};
