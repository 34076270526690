import React from "react";
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InfoArea from "components/InfoArea/InfoArea.js";

// custom icons

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

const useStyles = makeStyles(styles);

const ServicesSection = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title, classes.left)}>
            {text.title}
          </h2>
        </GridItem>
        {text.topics.map((topic) => (
          <GridItem key={topic} xs={12} sm={6} md={3} className={classes.hov}>
            <Link to="/profile">
              <InfoArea
                title={topic.title}
                description={topic.description}
                icon={topic.icon}
                vertical
                horizontal
              />
            </Link>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

export default ServicesSection;
