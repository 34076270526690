import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Backup } from "./backup.svg";

export default function BackupIcon(props) {
  return (
    <SvgIcon {...props}>
      <Backup />
    </SvgIcon>
  );
}
