import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @material-ui/icons

// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/defaultPage.js";

// Sections for this page
import DomainsSection from "./Sections/DomainsSection.js";
import PlatformSection from "./Sections/PlatformSection.js";
import ServicesSection from "./Sections/ServicesSection";
import HeaderSection from "views/Components/Sections/HeaderSection.js";

import banner_landscape from "assets/img/landing/banner_landscape.jpg";
import banner_portrait from "assets/img/landing/banner_portrait.jpg";

// Text for this page
import landingText from "./text/landingContent";

const useStyles = makeStyles(styles);

const LandingPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <HeaderSection props={props} />
      <Parallax filter image={matches ? banner_landscape : banner_portrait}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>
                {landingText.bannerSection.title}
              </h1>
              <h3 className={classes.subtitle}>
                {landingText.bannerSection.description}
              </h3>
              <br />
              {/*<Button
                color="danger"
                size="lg"
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button>*/}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ServicesSection text={landingText.servicesSection} />
          <DomainsSection text={landingText.productSection} />
          <PlatformSection text={landingText.platformSection} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
