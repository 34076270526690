import dashboard_01 from "assets/img/profile/dashboard_01.jpg";
import floor_plan_01 from "assets/img/profile/floor_plan_01.jpg";
import floor_plan_02 from "assets/img/profile/floor_plan_02.jpg";
import node_red_01 from "assets/img/profile/node_red_01.jpg";

export default {
  bannerSection: {
    title: "SHhI...",
    description: "Partenerul tău în soluții Smart Building",
  },
  companySection: {
    title: "Smart Household Integration",
    description:
      "Alături de noi, caminul tău poate raspunde simplu și ușor nevoilor tale." +
      "</br>Folosind soluțiile noastre, poți să îți crești gradul de confort al locuinței, să economisești bani, să dedici mai mult timp activităților preferate; casa ta se va ocupa de restul." +
      "</br>Oferim soluții complete pentru <strong>case</strong> și <strong>clădiri inteligente</strong>:" +
      "<ul>" +
      " <li><strong>Consultanta:</strong>  Pentru rezultate optime, un proiect smart începe cu o planificare detaliată. Suntem atenți la nevoile tale, le analizăm și împreună găsim soluția potrivită.</li>" +
      " <li><strong>Proiectare:</strong> Pornind de la concluziile primei faze vom elabora proiectele electric, HVAC, curenți slabi și smart.</li>" +
      " <li><strong>Implementare:</strong> Proiectul devine realitate cu ajutorul colaboratorilor noștri. Ne asigurăm de respectarea celor mai înalte standarde de calitate în execuție, garantănd astfel rezultatul.</li>" +
      " <li><strong>Suport:</strong> Continuăm drumul împreună – după punerea în funcțiune a sistemului vor urma etapele de predare si instruire. Apoi, pe parcursul a 6 săptămâni, ne vom asigura că totul funcționează conform așteptărilor. Dacă este necesar, intervenim pentru calibrarea suplimentară sistemului.</li>" +
      "  </br><em>Servicii postgaranție:</em>" +
      "  <ul>" +
      "   <li>Pachete de suport după expirarea celor 6 săptămâni gratuite.</li>" +
      "   <li>Pachet de Garanție Extinsă 3 ani.</li>" +
      "  </ul>" +
      "</ul>" +
      '</br>Integrăm în platforma <a href="https://www.home-assistant.io/" target="_blank" rel="noreferrer"> <strong>HomeAssistant</strong></a> sisteme bazate pe standardele <em><strong>WiFi, Z-Wave, ZigBee și BLE</strong></em> și nu numai.',
    src: dashboard_01,
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: floor_plan_02,
      title: "Plan Nivel",
      description:
        "Exemplu plan nivel iluminat cu control prin touch văzut de pe telefon.",
      cols: 1,
      rows: 2,
    },
    {
      img: floor_plan_01,
      title: "Plan Nivel",
      description:
        "Exemplu plan nivel iluminat cu control prin touch văzut de pe desktop/tabletă.",
      cols: 2,
    },
    {
      img: node_red_01,
      title: "Flux control",
      description: "Exemplu flux control sistem HVAC cu monitorizare prezentă.",
      cols: 2,
    },
  ],
  technicalSection: [
    {
      title: "Ce este WiFi?",
      description:
        "Wi-Fi is a family of wireless network protocols, based on the IEEE 802.11 family of standards, which are commonly used for local area networking of devices and Internet access.",
    },
    {
      title: "Ce este Z-Wave?",
      description:
        "Z-Wave is a wireless communications protocol used primarily for home automation. It is a mesh network using low-energy radio waves to communicate from appliance to appliance,[1] allowing for wireless control of residential appliances and other devices, such as lighting control, security systems, thermostats, windows, locks, swimming pools and garage door openers.",
    },
    {
      title: "Ce este ZigBee?",
      description:
        "Zigbee is an IEEE 802.15.4-based specification for a suite of high-level communication protocols used to create personal area networks with small, low-power digital radios, such as for home automation, medical device data collection, and other low-power low-bandwidth needs, designed for small scale projects which need wireless connection. Hence, Zigbee is a low-power, low data rate, and close proximity (i.e., personal area) wireless ad hoc network.",
    },
    {
      title: "Ce este BLE?",
      description:
        "Bluetooth Low Energy (Bluetooth LE, colloquially BLE, formerly marketed as Bluetooth Smart[1]) is a wireless personal area network technology designed and marketed by the Bluetooth Special Interest Group (Bluetooth SIG) aimed at novel applications in the healthcare, fitness, beacons,[2] security, and home entertainment industries.[3] It is independent of Bluetooth BR/EDR[clarification needed] and has no compatibility, but BR/EDR and LE can coexist.",
    },
  ],
  brandsSection: {
    title: "Gama de produse",
  },
};
