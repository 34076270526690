import ExtremeWeatherIcon from "assets/icons/safety/ExtremeWeatherIcon";
import FireIcon from "assets/icons/safety/FireIcon";
import WaterLeakIcon from "assets/icons/safety/WaterLeakIcon";
import CarbonMonoxideIcon from "assets/icons/safety/CarbonMonoxideIcon";
import GasLeakIcon from "assets/icons/safety/GasLeakIcon";

import safety_gas_01 from "assets/img/safety/safety_gas_01.jpg";
import safety_sensors_01 from "assets/img/safety/safety_sensors_01.jpg";

export default {
  bannerSection: {
    title: "",
    description: "Împreună, îți protejăm căminul!",
  },
  descriptionSection: {
    title: "Siguranță",
    subtitle: "Sisteme complete de protecție",
    description:
      "Optează pentru un sistem integrat de detecţie a scurgerilor de gaz, a inundaţiilor, incendiilor sau a monoxidului de carbon. Acesta va trimite notificări semnalizând defecțiunea, urmând să închidă, în mod automat, alimentarea cu gaze, electricitate sau apă şi să declanşeze alerte locale sau la distanţă. Mai mult decât atât, va aerisi interiorul dacă este cazul.",
    img: safety_gas_01,
  },
  advantagesSection: {
    title: "Apariția unei defecțiuni",
    items: [
      {
        title: "Scurgeri de gaze",
        description:
          "Întrerupe alimentarea cu gaze, declanșează alarma de evacuare și ventilează clădirea.",
        icon: GasLeakIcon,
      },
      {
        title: "Fum",
        description:
          "Declanșează alarma de incendiu și pornește sistemul antiincendiu localizat.",
        icon: FireIcon,
      },
      {
        title: "Monoxid de carbon",
        description:
          "Ventilează clădirea; declanșează alarme vizuale și sonore.",
        icon: CarbonMonoxideIcon,
      },
      {
        title: "Scurgeri de apă",
        description:
          "Întrerupe alimentarea cu apa; trimite notificări și indică locul unde a avut loc scurgerea",
        icon: WaterLeakIcon,
      },
      {
        title: "Vreme extremă",
        description:
          "Fiind conectat la stații meteo locale, în cazul unor fenomene meteo extreme sistemul emite notificări pentru închiderea căilor de acces, a ferestrelor și, implict, alertează părinții în cazul în care copiii sunt afară.",
        icon: ExtremeWeatherIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: safety_sensors_01,
      title: "Image",
      description: "author",
      rows: 2,
      cols: 1,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/safety_gas_01.mp4",
      title: "Demo Detector Gaz non-smart cu implant",
      description: "Activare scenă scurgere de gaz prin detector cu implant.",
      cols: 2,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/safety_01.mp4",
      title: "Demo senzor de umiditate",
      description: "Delay insesizabil în detectarea scurgerilor de apă.",
      cols: 2,
    },
  ],
};
