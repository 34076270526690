import React from "react";

// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/defaultPage.js";

// Sections for this page
import HighlightsSection from "views/Components/Sections/HighlightsSection";
import DescriptionSection from "views/Components/Sections/DescriptionSection";
import GallerySection from "views/sections/GallerySection";
import TechnicalDetailsSection from "views/Components/Sections/TechnicalDetailsSection";

// Text for this page

const useStyles = makeStyles(styles);

export default function ServiceSection(props) {
  const { ...text } = props.text;
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <DescriptionSection text={text.descriptionSection} />
            <HighlightsSection text={text.advantagesSection} />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GallerySection
              tiles={text.gallerySection}
              settings={text.gallerySectionSettings}
            />
          </GridItem>
          {"technicalSection" in text ? (
            <GridItem xs={12} sm={12} md={12}>
              <TechnicalDetailsSection text={text.technicalSection} />
            </GridItem>
          ) : (
            ""
          )}
        </GridContainer>
      </div>
    </div>
  );
}
