import SmartphoneIcon from "assets/icons/hvac/SmartphoneIcon";
import MonitoringIcon from "assets/icons/hvac/MonitoringIcon";
import CutCostIcon from "assets/icons/hvac/CutCostIcon";
import SyncIcon from "assets/icons/hvac/SyncIcon";
import AirFilterIcon from "assets/icons/hvac/AirFilterIcon";
import VentingIcon from "assets/icons/hvac/VentingIcon";
import ThermometerIcon from "assets/icons/hvac/ThermometerIcon";

import hvac_dashboard from "assets/img/hvac/hvac_dashboard.jpg";
import hvac_01 from "assets/img/hvac/hvac_01.jpg";
import hvac_02 from "assets/img/hvac/hvac_02.jpg";
import hvac_control_01 from "assets/img/hvac/hvac_control_01.jpg";

export default {
  bannerSection: {
    title: "",
    description:
      "Sistemul inteligent HVAC (Heating Ventilation and Air Conditioning) oferă un climat sănătos locuinţei tale.",
  },
  descriptionSection: {
    title: "Smart HVAC",
    subtitle:
      "Sisteme inteligente de comandă și control a climatizării și ventilației",
    description:
      "Încăperi neaerisite, prea calde sau prea reci? Alege prospeţimea şi temperatura optimă pentru confortul tău. Cu sistemul Smart HVAC vei avea temperatura potrivită în orice moment al zilei în fiecare încăpere – aer proaspăt dimineaţa, fără urme vizibile de praf pe suprafeţe. De altfel, un aspect la fel de important este şi energia economisită.",
    img: hvac_dashboard,
  },
  advantagesSection: {
    title: "Avantaje",
    items: [
      {
        title: "Optimizarea temperaturii",
        description:
          "Păstrarea unei temperaturi constante, în funcţie de nevoi.",
        icon: ThermometerIcon,
      },
      {
        title: "Ventilație continuă",
        description: "Aer proaspăt în orice moment al zilei.",
        icon: VentingIcon,
      },
      {
        title: "Filtrare aer",
        description:
          "Filtre ce împiedică pătrunderea particulelor şi permit accesul ușor pentru curăţare, dezinfectare sau înlocuire.",
        icon: AirFilterIcon,
      },
      {
        title: "Monitorizare parametrii de calitate a aerului",
        description:
          "Consultarea din aplicație a parametrilor ce reflectă calitatea aerului, precum derivaţi de sulf, azot, precursori de ozon, etc.",
        icon: MonitoringIcon,
      },
      {
        title: "Reducerea costurilor",
        description: "Identificarea automată a posibilităţilor de economisire.",
        icon: CutCostIcon,
      },
      {
        title: "Sincronizare cu programul dumneavoastră",
        description:
          "Definirea şi/sau personalizarea modurilor de funcţionare.",
        icon: SyncIcon,
      },
      {
        title: "Acces de pe dispozitiv smart",
        description: "Simplu şi uşor, cu doar un click.",
        icon: SmartphoneIcon,
      },
    ],
  },
  gallerySectionSettings: {
    cols: 3,
  },
  gallerySection: [
    {
      img: hvac_01,
      title: "Echipamente control HVAC",
      description:
        "Echipamente utilizabile pentru controlul hvac prin distribuitor.",
      cols: 2,
    },
    {
      img: hvac_control_01,
      title: "Control HVAC prin telefon/tabletă",
      description: "Exemplu configurare control HVAC prin telefon/tabletă",
      cols: 1,
      rows: 2,
    },
    {
      img: hvac_02,
      title: "Echipamente control HVAC",
      description:
        "Echipamente utilizabile pentru controlul hvac prin calorifere/ventiloconvectori.",
      cols: 2,
    },
    {
      vid: "https://shhi.fra1.digitaloceanspaces.com/hvac_01.mp4",
      cols: 3,
    },
  ],
  technicalSection: [
    {
      title: "Ce este HVAC?",
      description:
        "HVAC provine de la Încălzire(H), Ventilaţie(V), Aer Condiţionat(AC)." +
        "<br />" +
        "Rolul sistemului HVAC în locuință este acela de a menține confortul termic și calitatea aerului la un nivel optim." +
        "<ul>" +
        "  <li>" +
        "    Confortul termic este asigurat prin controlul temperaturii și al umidității utilizând sisteme de încălzire şi/sau aer condiţionat." +
        "  </li>" +
        "  <li>" +
        "    Calitatea aerului este asigurată prin ventilaţie manuală (aerisind spaţiul) sau prin sisteme de ventilaţie cu recuperare de căldură." +
        "  </li>" +
        "</ul>" +
        "Principalele obiective ale unui sistem HVAC sunt:" +
        "<ul>" +
        "  <li>Controlul temperaturii</li>" +
        "  <li>Evacuarea aerului viciat și introducerea aerului proaspăt</li>" +
        "  <li>Recuperarea energiei</li>" +
        "</ul>" +
        "Eficienţa energetică a sistemului HVAC este dată de echipamentele instalate și modul în care le utilizăm. Un sistem <strong>Inteligent de comandă și control al echipamentelor HVAC</strong> aduce beneficii multiple:" +
        "<ul>" +
        "  <li>Confort sporit, prin controlul parametrilor simplu și rapid</li>" +
        "  <li>Economie de energie – toate procesele se realizează  automat în funcție de setările dorite</li></ul>",
    },
  ],
};
