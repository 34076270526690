import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Hvac } from "./hvac.svg";

export default function HvacIcon(props) {
  return (
    <SvgIcon {...props}>
      <Hvac />
    </SvgIcon>
  );
}
