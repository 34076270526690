import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as AirFilter } from "./airFilter.svg";

export default function AirFilterIcon(props) {
  return (
    <SvgIcon {...props}>
      <AirFilter />
    </SvgIcon>
  );
}
