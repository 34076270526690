import React from "react";

import { SvgIcon } from "@material-ui/core";
import { ReactComponent as LightBulb } from "./lightBulb.svg";

export default function LightBulbIcon(props) {
  return (
    <SvgIcon {...props}>
      <LightBulb />
    </SvgIcon>
  );
}
