import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as SmartDoor } from "./smartDoor.svg";

export default function SmartDoorIcon(props) {
  return (
    <SvgIcon {...props}>
      <SmartDoor />
    </SvgIcon>
  );
}
