import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Sync } from "./sync.svg";

export default function SyncIcon(props) {
  return (
    <SvgIcon {...props}>
      <Sync />
    </SvgIcon>
  );
}
