import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as DualWan } from "./dualWan.svg";

export default function DualWanIcon(props) {
  return (
    <SvgIcon {...props}>
      <DualWan />
    </SvgIcon>
  );
}
