import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as ExtremeWeather } from "./extremeWeather.svg";

export default function ExtremeWeatherIcon(props) {
  return (
    <SvgIcon {...props}>
      <ExtremeWeather />
    </SvgIcon>
  );
}
