import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import { ImageList, ImageListItem, ImageListItemBar } from "@material-ui/core";

import styles from "assets/jss/material-kit-react/views/commonSections/gallerySection.js";
import ReactPlayer from "react-player";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(styles);

const GallerySection = (props) => {
  const tilesData = props.tiles;
  const sectionSettings = props.settings || { cols: 2 };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <ImageList
          className={classes.gridList}
          cols={sectionSettings.cols || 1}
          gap={4}
        >
          {tilesData.map((tile) => (
            <ImageListItem
              key={tile.img}
              cols={tile.cols || 1}
              rows={tile.rows || 1}
            >
              {"img" in tile ? (
                <img src={tile.img} alt={tile.title} />
              ) : (
                <ReactPlayer
                  controls="true"
                  muted="true"
                  url={tile.vid}
                  width="100%"
                  height="100%"
                />
              )}
              {"img" in tile ? (
                <ImageListItemBar
                  title={tile.title}
                  subtitle={tile.description}
                />
              ) : null}
            </ImageListItem>
          ))}
        </ImageList>
      </Hidden>
      <Hidden smUp>
        <ImageList className={classes.gridList} cols={1} gap={4}>
          {tilesData.map((tile) => (
            <ImageListItem key={tile.img} cols={1}>
              {"img" in tile ? (
                <img src={tile.img} alt={tile.title} />
              ) : (
                <ReactPlayer
                  controls="true"
                  muted="true"
                  url={tile.vid}
                  width="100%"
                  height="100%"
                />
              )}
              {"img" in tile ? (
                <ImageListItemBar
                  title={tile.title}
                  subtitle={tile.description}
                />
              ) : null}
            </ImageListItem>
          ))}
        </ImageList>
      </Hidden>
    </div>
  );
};

export default GallerySection;
