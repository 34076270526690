import React from "react";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// custom icons

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

import haDashboard from "assets/img/landing/ha_dashboard.png";

const useStyles = makeStyles(styles);

const PlatformSection = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid,
    classes.imgCenter
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classNames(classes.title, classes.left)}>
            {text.title}
          </h2>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} className={classes.centerVertical}>
          <h3 className={classNames(classes.subTitle, classes.left)}>
            {text.subTitle}
          </h3>
          <p className={classes.description}>{text.description}</p>
          <Button
            type="button"
            color="primary"
            href="https://www.home-assistant.io/"
            target="_blank"
            simple
            className={classes.right}
          >
            {text.button}
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={7}>
          <img src={haDashboard} alt="..." className={imageClasses} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default PlatformSection;
