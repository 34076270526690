import React from "react";

import { SvgIcon } from "@material-ui/core";

import { ReactComponent as Pool } from "./pool.svg";

export default function PoolIcon(props) {
  return (
    <SvgIcon {...props}>
      <Pool />
    </SvgIcon>
  );
}
