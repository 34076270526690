import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

import ReactHtmlParser from "react-html-parser";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/commonSections/defaultSection.js";

const useStyles = makeStyles(styles);

const ArticleDescription = (props) => {
  const { ...text } = props.text;
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgFluid,
    classes.imgCenter
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>{text.title}</h2>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} className={classes.centerVertical}>
          <h3 className={classes.subTitle}>{text.subtitle}</h3>
          <h5 className={classes.description}>
            {ReactHtmlParser(text.description)}
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <img src={text.image} alt="..." className={imageClasses} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ArticleDescription;
