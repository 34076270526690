import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";
import HeaderSection from "views/Components/Sections/HeaderSection.js";

import styles from "assets/jss/material-kit-react/views/defaultPage.js";

// Sections for this page
import ServiceSection from "./sections/ServiceSection";

// Text for this page
import servicesText from "./text/servicesContent";

import accessText from "./text/accessContent";
import access_banner_landscape from "assets/img/access/banner_landscape.jpg";
import access_banner_portrait from "assets/img/access/banner_portrait.jpg";

import gardenText from "./text/gardenContent";
import garden_banner_landscape from "assets/img/garden/banner_landscape.jpg";
import garden_banner_portrait from "assets/img/garden/banner_portrait.jpg";

import hvacText from "./text/hvacContent";
import hvac_banner_landscape from "assets/img/hvac/banner_landscape.jpg";
import hvac_banner_portrait from "assets/img/hvac/banner_portrait.jpg";

import lightAndSocketsText from "./text/lightAndSocketsContent";
import lightAndSockets_banner_landscape from "assets/img/lightAndSockets/banner_landscape.jpg";
import lightAndSockets_banner_portrait from "assets/img/lightAndSockets/banner_portrait.jpg";

import mediaServerText from "./text/mediaServerContent";
import mediaServer_banner_landscape from "assets/img/mediaServer/banner_landscape.jpg";
import mediaServer_banner_portrait from "assets/img/mediaServer/banner_portrait.jpg";

import networkingText from "./text/networkingContent";
import networking_banner_landscape from "assets/img/networking/banner_landscape.jpg";
import networking_banner_portrait from "assets/img/networking/banner_portrait.jpg";

import safetyText from "./text/safetyContent";
import safety_banner_landscape from "assets/img/safety/banner_landscape.jpg";
import safety_banner_portrait from "assets/img/safety/banner_portrait.jpg";

import securityText from "./text/securityContent";
import security_banner_landscape from "assets/img/security/banner_landscape.jpg";
import security_banner_portrait from "assets/img/security/banner_portrait.jpg";

import { Box, Tab } from "@material-ui/core";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import HvacIcon from "../../assets/icons/HvacIcon";
import SafetyIcon from "../../assets/icons/SafetyIcon";
import LightBulbIcon from "../../assets/icons/LightBulbIcon";
import AccessIcon from "../../assets/icons/AccessIcon";
import SecurityIcon from "../../assets/icons/SecurityIcon";
import MediaServerIcon from "../../assets/icons/MediaServerIcon";
import NetworkingIcon from "../../assets/icons/NetworkingIcon";
import GardenIcon from "../../assets/icons/GardenIcon";

const useStyles = makeStyles(styles);

export default function ServicesPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [value, setValue] = React.useState(
    props.location.pathname === "/services" ||
      props.location.pathname === "/services/hvac"
      ? "1"
      : props.location.pathname === "/services/safety"
      ? "2"
      : props.location.pathname === "/services/light-and-sockets"
      ? "3"
      : props.location.pathname === "/services/access"
      ? "4"
      : props.location.pathname === "/services/security"
      ? "5"
      : props.location.pathname === "/services/media-server"
      ? "6"
      : props.location.pathname === "/services/networking"
      ? "7"
      : props.location.pathname === "/services/garden"
      ? "8"
      : "N/A"
  );
  const [pageText, setPageText] = React.useState(
    value === "1"
      ? hvacText
      : value === "2"
      ? safetyText
      : value === "3"
      ? lightAndSocketsText
      : value === "4"
      ? accessText
      : value === "5"
      ? securityText
      : value === "6"
      ? mediaServerText
      : value === "7"
      ? networkingText
      : gardenText
  );
  const [banner_landscape, setBannerLandscape] = React.useState(
    value === "1"
      ? hvac_banner_landscape
      : value === "2"
      ? safety_banner_landscape
      : value === "3"
      ? lightAndSockets_banner_landscape
      : value === "4"
      ? access_banner_landscape
      : value === "5"
      ? security_banner_landscape
      : value === "6"
      ? mediaServer_banner_landscape
      : value === "7"
      ? networking_banner_landscape
      : garden_banner_landscape
  );
  const [banner_portrait, setBannerPortrait] = React.useState(
    value === "1"
      ? hvac_banner_portrait
      : value === "2"
      ? safety_banner_portrait
      : value === "3"
      ? lightAndSockets_banner_portrait
      : value === "4"
      ? access_banner_portrait
      : value === "5"
      ? security_banner_portrait
      : value === "6"
      ? mediaServer_banner_portrait
      : value === "7"
      ? networking_banner_portrait
      : garden_banner_portrait
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPageText(
      newValue === "1"
        ? hvacText
        : newValue === "2"
        ? safetyText
        : newValue === "3"
        ? lightAndSocketsText
        : newValue === "4"
        ? accessText
        : newValue === "5"
        ? securityText
        : newValue === "6"
        ? mediaServerText
        : newValue === "7"
        ? networkingText
        : gardenText
    );
    setBannerLandscape(
      newValue === "1"
        ? hvac_banner_landscape
        : newValue === "2"
        ? safety_banner_landscape
        : newValue === "3"
        ? lightAndSockets_banner_landscape
        : newValue === "4"
        ? access_banner_landscape
        : newValue === "5"
        ? security_banner_landscape
        : newValue === "6"
        ? mediaServer_banner_landscape
        : newValue === "7"
        ? networking_banner_landscape
        : garden_banner_landscape
    );
    setBannerPortrait(
      newValue === "1"
        ? hvac_banner_portrait
        : newValue === "2"
        ? safety_banner_portrait
        : newValue === "3"
        ? lightAndSockets_banner_portrait
        : newValue === "4"
        ? access_banner_portrait
        : newValue === "5"
        ? security_banner_portrait
        : newValue === "6"
        ? mediaServer_banner_portrait
        : newValue === "7"
        ? networking_banner_portrait
        : garden_banner_portrait
    );
  };

  return (
    <div>
      <HeaderSection props={props} />
      <Parallax
        filter
        small
        image={matches ? banner_landscape : banner_portrait}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>{pageText.bannerSection.title}</h1>
              <h3 className={classes.subtitle}>
                {pageText.bannerSection.description}
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              classes={{
                root: classes.contentCenter,
                scroller: classes.contentScrollable,
              }}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile={true}
            >
              <Tab
                icon={<HvacIcon />}
                label={servicesText.tabsLabels.Hvac}
                href="#services/hvac"
                value="1"
              />
              <Tab
                icon={<SafetyIcon />}
                label={servicesText.tabsLabels.Safety}
                href="#services/safety"
                value="2"
              />
              <Tab
                icon={<LightBulbIcon />}
                label={servicesText.tabsLabels.LightAndSockets}
                href="#services/light-and-sockets"
                value="3"
              />
              <Tab
                icon={<AccessIcon />}
                label={servicesText.tabsLabels.Access}
                href="#services/access"
                value="4"
              />
              <Tab
                icon={<SecurityIcon />}
                label={servicesText.tabsLabels.Security}
                href="#services/security"
                value="5"
              />
              <Tab
                icon={<MediaServerIcon />}
                label={servicesText.tabsLabels.MediaServer}
                href="#services/media-server"
                value="6"
              />
              <Tab
                icon={<NetworkingIcon />}
                label={servicesText.tabsLabels.Networking}
                href="#services/networking"
                value="7"
              />
              <Tab
                icon={<GardenIcon />}
                label={servicesText.tabsLabels.Garden}
                href="#services/garden"
                value="8"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="2">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="3">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="4">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="5">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="6">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="7">
            <ServiceSection text={pageText} />
          </TabPanel>
          <TabPanel value="8">
            <ServiceSection text={pageText} />
          </TabPanel>
        </TabContext>
      </div>
      <Footer />
    </div>
  );
}
